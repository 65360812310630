import type { AccountInfo } from 'owa-analytics-types';
import {
    type CoprincipalAccountExternalData,
    isCoprincipalAccountExternalData,
} from '../store/schema/ExternalData';
import { AccountSourceType } from 'owa-account-source-list-types';
import getCoprincipalAccounts from '../selectors/getCoprincipalAccounts';
import getAccountNumberByType from '../selectors/getAccountNumberByType';
import type { CoprincipalAccountSource } from '../store/schema/AccountSourceList';

function isPuidRoutingHintPresent(account: CoprincipalAccountSource): boolean {
    if (isCoprincipalAccountExternalData(account.externalData)) {
        const accountData: CoprincipalAccountExternalData = account.externalData;
        return !!accountData.account.routingHint?.startsWith('PUID:');
    }

    return false;
}

/**
 * Collects the account information for the current session for analytics
 * @returns The AccountInfo to be reported in the session datapoint
 */
export function getSessionAccountInfo(): AccountInfo {
    const accounts = getCoprincipalAccounts();

    const withPuid = accounts.filter(account => isPuidRoutingHintPresent(account)).length;

    return {
        numberOfLoggedInAccounts: getCoprincipalAccounts().length,
        totalAccounts: getCoprincipalAccounts().length,
        withPuid,
        office365: getAccountNumberByType(AccountSourceType.Office365),
        outlookDotCom: getAccountNumberByType(AccountSourceType.OutlookDotCom),
        google: getAccountNumberByType(AccountSourceType.Google),
        yahoo: getAccountNumberByType(AccountSourceType.Yahoo),
        icloud: getAccountNumberByType(AccountSourceType.ICloud),
        imap: getAccountNumberByType(AccountSourceType.IMAP),
        pop3: getAccountNumberByType(AccountSourceType.POP3),
        pst: getAccountNumberByType(AccountSourceType.PstFile),
    };
}
